.pricing-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 70px 20px;
  background-color: #ff5a36;
}

@media (max-width: 768px) {
  .pricing-section {
    flex-direction: column;
    gap: 20px;
  }
}

.pricing-card {
  background-color: white;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.pricing-title {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
}

.pricing-features {
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
}

.pricing-features li {
  font-size: 16px;
  color: #1e1e1e;
  margin-bottom: 10px;
}

.pricing-price {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.pricing-currency {
  font-weight: normal;
  font-size: 16px;
  color: #333;
}

.cta-button-pricing {
  background-color: transparent;
  color: #ff5a36;
  padding: 8px 30px;
  border-radius: 50px;
  border: 2px #ff5a36 solid;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.cta-button-pricing:hover {
  background-color: #ff5a36;
  color: white;
}

.pricing-description {
  max-width: 600px;
  margin-left: 40px;
}

@media (max-width: 768px) {
  .pricing-description {
    margin-left: 0px;
  }
}

.description-title {
  font-size: 40px;
  color: white;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .description-title {
    font-size: 32px;
    text-align: center;
  }
}