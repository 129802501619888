.text-section {
    text-align: left;
    padding: 40px 20px;
    margin-left: 30%;
    width: 40%;
}

@media (max-width: 1200px) {
    .text-section {
        padding: 40px 20px;
        margin-left: 5%;
        width: 90%;
    }
}

@media (max-width: 768px) {
    .text-section {
        text-align: center;
        padding: 10px;
        margin-left: 0%;
        width: auto;
    }
}

.text-title {
    font-size: 36px;
    font-weight: bold;
    color: #1e1e1e;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .text-title {
        font-size: 32px;
    }
}

.text-description {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
}

.cta-button {
    background-color: #ff5a36;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #DC2A04;
}