.feature-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 150px 20px;
    gap: 70px;
}

@media (max-width: 768px) {
    .feature-section {
        padding: 50px 20px;
    }
    
}

.feature-left {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.message-bubble {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    font-size: 16px;
    color: #1e1e1e;
    line-height: 1.5;
}

.first-bubble {
    border: 1px #ccc solid;
    max-width: 350px;
    margin-left: -50px;
}

@media (max-width: 768px) {
    .first-bubble {
        width: auto;
        margin-left: 0px;
    }
}

.second-bubble {
    border: 1px #ff5a36 solid;
    background: rgba(255, 87, 51, 0.1);
    max-width: 500px;
    margin-left: 50px;
}

@media (max-width: 768px) {
    .second-bubble {
        width: auto;
        margin-left: 0px;
    }
}

.user-bubble {
    align-self: flex-start; /* Align user bubble to the start */
}

.ai-bubble {
    align-self: flex-end; /* Align AI bubble to the end */
    margin-top: -40px; /* Overlap effect */
}

.generate-button {
    position: absolute;
    top: 150px;
    left: 230px;
    border: none;
    background: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .generate-button {
        right: 20px;
        left: auto;
    }
}

.generate-button img {
    width: auto;
    height: auto;
}

.feature-right {
    max-width: 500px;
    margin-left: 40px; /* Space between left and right sections */
}

.feature-right .cta-button {
    text-align: left;
}

.feature-title {
    font-size: 36px;
    color: #1e1e1e;
    margin-bottom: 20px;
    text-align: left;
}

@media (max-width: 768px) {
    .feature-title {
        font-size: 32px;
        text-align: center;
    }
}

.feature-description {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
    text-align: left;
}

@media (max-width: 768px) {
    .feature-description {
        text-align: center;
    }
}

.cta-button {
    background-color: #ff5a36;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #DC2A04;
}

@media (max-width: 768px) {
    .feature-section {
        flex-direction: column; /* Stack on mobile */
    }

    .feature-left {
        order: 1; /* Make sure left section is on top */
    }

    .feature-right {
        order: 2; /* Move right section below */
        margin-left: 0; /* Reset margin for mobile */
        text-align: center; /* Center text for mobile */
    }

    .message-bubble {
        max-width: 100%; /* Full width on mobile */
    }
}
