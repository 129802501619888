.hero-section {
  background-color: #fff;
  padding: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 0px;
  }
}

.hero-section-content {
  max-width: 1000px;
  margin: 0 auto;
}

.hero-section-title {
  font-size: 60px;
  font-weight: bold;
  color: #1e1e1e;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .hero-section-title {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

.hero-section-subtitle {
  font-size: 18px;
  color: #666;
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .hero-section-subtitle {
    margin-bottom: 20px;
  }
}

.hero-video-container {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  height: 400px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .hero-video-container {
      width: 100%;
      margin-left: 0%;
  }
}

.hero-video {
  width: 100%;
  overflow: hidden;
}

.hero-section-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.hero-chrome-store {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.hero-chrome-store-icon {
  width: 40px;
  height: 40px;
}

.cta-button {
  background-color: #ff5a36;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #DC2A04;
}

@media (max-width: 768px) {
  .hero-section-title {
    font-size: 36px;
  }

  .hero-video-container {
    height: 250px;
  }

  .hero-section-buttons {
    flex-direction: column;
  }
}
