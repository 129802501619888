.footer {
    background-color: #f2f2f2;
    padding: 40px 20px;
    text-align: center;
}

.footer p {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.social-icons span {
    font-size: 24px;
    color: #ff5a36;
    cursor: pointer;
}

.social-icons span:hover {
    color: #DC2A04;
}
  