.testimonial {
    padding: 60px 20px;
    text-align: center;
}

.testimonial blockquote {
    font-size: 48px;
    font-style: italic;
    line-height: 1.6;
    color: #333;
    width: 70%;
    margin-left: 15%;
}

@media (max-width: 768px) {
    .testimonial blockquote {
        font-size: 34px;
        width: 100%;
        margin-left: 0%;
    }
}