.login-page {
    z-index: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-container {
    width: 400px;
    margin: auto;
    border-radius: 12px;
}

.login-title {
    font-size: 36px;
    color: #1e1e1e;
    margin-bottom: 10px;
    text-align: center;
}

.login-subtitle {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
    text-align: center;
}

.text-input {
    width: 100%;
    max-width: 370px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 50px;
    font-size: 16px;
    margin-bottom: 20px;
}

.next-button {
    width: 100%;
    padding: 15px;
    background-color: #ff5a36;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.next-button:hover {
    background-color: #DC2A04;
}

.divider {
    margin: 20px 0;
    text-align: center;
}

.divider span {
    color: #666;
}

.account-text {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
    text-align: center;
    cursor: pointer;
}
