.header {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

@media (max-width: 768px) {
    .header {
        padding: 10px 20px;
    }
}

.brand-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.brand {
    font-size: 24px;
    font-weight: bold;
    color: #FF5733;
    cursor: pointer;
}

.logo {
    margin-right: 8px;
    height: 24px;
    cursor: pointer;
}

.user-icon {
    font-size: 24px;
    height: 40px;
    cursor: pointer;
}

.user-icon:hover {
    background-color: rgba(255, 87, 51, 0.1);
    border-radius: 50%;
}